import axios from "axios";
import { showToast } from "../../../../../master/utils/ToastHelper";
import * as Types from "../../types/Types";
import Enumerable from 'linq';

export const itemAddInput = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };

  dispatch({ type: Types.GET_SEARCH_DATA_INPUT, payload: formData });
};
export const InputHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_PRODUCT_INPUT, payload: formData });
};
export const submitStatusRemove = () => (dispatch) => {
  dispatch({ type: Types.ZONE_SUBMIT_STATUS_REMOVE, payload: null });
};
export const productSubmitStausRemove = () => (dispatch) => {
  dispatch({ type: Types.PRODUCT_SUBMIT_STATUS_REMOVE, payload: null });
};
export const handleSubmit = (inputData) => (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/ZoneEntry?ZoneName=${inputData.zoneName}`;
    axios
      .post(postUrl)
      .then(function (response) {
        //   responseList.data = response.data;
        responseList.isLoading = false;
        responseList.status = response.data.status;
        if (response.data.status) {
          showToast("success", response.data.messege);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {
        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({
    type: Types.ZONE_SAVE,
    payload: responseList,
  });
};
export const getDistributorList = (areaId, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerbyUnit?unitId=98&AreaID=${areaId}&DistribCat=${salesTypeId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_LIST, payload: responseList });
};
export const getSOListByArea = async (areaId, date) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSOListbyAreaforWeb?AreaID=${areaId}&SSDate=${date}`;

  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  return responseList;
  // dispatch({ type: Types.GET_SO_LIST_BY_AREA, payload: responseList });
};
export const getSOPointByArea = async (areaId,territoryId, date) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSOPointListbyArea?AreaID=${areaId}&TerritoryId=${territoryId}&DDSate=${date}`;

  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  return responseList;
  // dispatch({ type: Types.GET_SO_LIST_BY_AREA, payload: responseList });
};
export const getItemforStockProduct = (soPointId, startDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SecondarySales/GetItemforSSwithProductStock?SOPointID=${soPointId}&SalesDate=${startDate}`;
  try {
    const res = await axios.get(url);
    var result = res.data.data;
    console.log('stock result', result);
    responseList.data = result;
    responseList.status = false;
    
  } catch (error) {
    console.error('Error fetching stock data:', error);
    responseList.status = true;
  }
  dispatch({ type: Types.GET_STOCK_SECONDARY_PRODUCT, payload: responseList });
};

export const getItemValidationforStockProduct =  (soPointId,startDate,item)  => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
    item:item
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SecondarySales/GetItemforSSwithProductStock?SOPointID=${soPointId}&SalesDate=${startDate}`;


  await axios.get(url).then((res) => {

    var result = Enumerable.from(res.data.data)
    .where(w => w.itemID ==item.itemID)
    .toArray();
    responseList.data = result;
    dispatch({ type: Types.GET_STOCK_SECONDARY_PRODUCT_VALIDATION, payload: responseList });

  })
  
  
  // return responseList;
 
};
export const getItemValidationforStockUpdateProduct =  (soPointId,startDate,item)  => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
    item:item
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SecondarySales/GetItemforSSwithProductStock?SOPointID=${soPointId}&SalesDate=${startDate}`;

  await axios.get(url).then((res) => {
    var result = Enumerable.from(res.data.data)
    .where(w => w.itemID ==item.itemID)
    .toArray();
    responseList.data = result;

  })
 
  //   responseList.data = res.data.data;
  //   responseList.status = false;
  // });
  // return responseList;
  dispatch({ type: Types.GET_STOCK_SECONDARY_PRODUCT_VALIDATION_UPDATE, payload: responseList });
};

export const getSOListTemp = async (areaId, date) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSalesTemp/GetSOListbyAreaTemp?AreaID=${areaId}&SSDate=${date}`;
  await axios.get(url).then((res) => {
    
    responseList.data = res.data.data;
    responseList.status = false;
  });
  return responseList;
  // dispatch({ type: Types.GET_SO_LIST_BY_AREA, payload: responseList });
};
// export const getSOListByArea = async (areaId)  => {
//   let responseList = {
//     isLoading: true,
//     data: {},
//     status: true,
//   };
//   let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetSOListbyArea?AreaID=${areaId}`;
//   await axios.get(url).then((res) => {
//     responseList.data = res.data.data;
//     responseList.status = false;
//   });
//   return responseList;

// };
export const EditDateGet = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_SALES_LIST_EDIT_DATA, payload: item });
};
export const getCustomerBalance = (customerId) => async (dispatch) => {


  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetCustomerDetailsByID?CustomerID=${customerId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_CUSTOMER_BALANCE, payload: responseList });
};

export const getProductList = (salesType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SecondarySales/GetItemforSS`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST, payload: responseList });
};
export const GetImsTrackingSystem = (menu_name,action_name,macAddress,action_id) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}IMSTrackingSystem?menu_name=${menu_name}&action_name=${action_name}&macAddress=${macAddress}&action_id=${action_id}`;
  await axios.post(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  
};

export const getProductListForTemp = (salesType) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: null,
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SecondarySales/GetItemforSSTemp`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_LIST_FOR_TEMP, payload: responseList });
};

export const getSalesList = (areaid, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderReport?AreaID=${areaid}&DistribCat=${salesTypeId}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_PRODUCT_DEMAND_LIST, payload: responseList });
};
export const showApprovedReport = (fromDate, todate, areaId, salesTypeId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSalesDemandConfirmReport?FromDate=${fromDate}&ToDate=${todate}&AreaID=${areaId}&DistribCat=${salesTypeId}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_APPROVED_SALES_REPORT, payload: responseList });
};
export const getCustomerProductDelivery = (areaid, fromDate, toDate) => async (dispatch) => {

  let responseList = {
    isPProductLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_PRODUCT_DELIVERY, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReport?AreaID=${areaid}&FromDate=${fromDate}&ToDate=${toDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isPProductLoading = false
  });
  dispatch({ type: Types.GET_CUSTOMER_PRODUCT_DELIVERY, payload: responseList });
};
export const getSalesListDetails = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderDetailsReport?SalesOrderInvID=${orderId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_ORDER_DETAILS, payload: responseList });
};
export const getSalesApprovedDetails = (invoiceId) => async (dispatch) => {
  let responseList = {
    approvedLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSalesDemandConfirmReportDetails?InvoiceID=${invoiceId}`;
  await axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.approvedLoading = false;
  });
  dispatch({ type: Types.GET_SALES_APPROVED_DETAILS, payload: responseList });
};
export const getDistributorDeliveryDetails = (customerId, fromDate, toDate) => async (dispatch) => {
  let responseList = {
    isDeDetails: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_CUSTOMER_PROD_DETAILS, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReportDetails?CustomerID=${customerId}&FromDate=${fromDate}&ToDate=${toDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isDeDetails = false;
  }).catch(function (error) {

    responseList.isLoading = false;
    const message =
      "Something went wrong ! Please fill all inputs and try again !";
    showToast("error", message);
  });
  dispatch({ type: Types.GET_CUSTOMER_PROD_DETAILS, payload: responseList });
};
export const checkgetDistributorDeliveryDetails = async (customerId, fromDate, toDate) => {
  let responseList = {
    isDeDetails: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryReportDetails?CustomerID=${customerId}&FromDate=${fromDate}&ToDate=${toDate}`;

  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isDeDetails = false;
  }).catch(function (error) {

    responseList.isLoading = false;
    const message =
      "Something went wrong ! Please fill all inputs and try again !";
    showToast("error", message);
  });

};
export const getUpdateProductList = (orderId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderDetailsReportforUpdate?SalesOrderInvID=${orderId}`;
  await axios.get(url).then((res) => {

    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({
    type: Types.GET_SALES_ORDER_UPDATE_DETAILS,
    payload: responseList,
  });
};

export const productSelecteditem = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_PRODUCT_SELECTED, payload: item });
};
export const delteProductList = (item) => async (dispatch) => {
  dispatch({ type: Types.GET_PRODUCT_DELETE, payload: item });
};
export const productQuantity = (name, value) => async (dispatch) => {
  const formData = {
    item: name,
    value: value,
  };

  dispatch({ type: Types.CHANGE_PRODUCT_QUANTITY, payload: formData });
};
export const DemoProductQuantity = (name, value) => async (dispatch) => {
  const formData = {
    item: name,
    value: value,
  };

  dispatch({ type: Types.CHANGE_PRODUCT_QUANTITY_DEMO, payload: formData });
};
export const productUpdateInputQuantity = (name, value) => async (dispatch) => {
  const formData = {
    item: name,
    value: value,
  };

  dispatch({ type: Types.CHANGE_PRODUCT_UPDATE_QUANTITY, payload: formData });
};
export const getSODetailsBySOID = async (soId) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/GetDistributorbySO?SOID=${soId}`;
  await axios.get(url).then(res => {

    responseList.data = res.data.data;
    responseList.status = false;
  })
    .catch(function (error) {

    });
  return responseList;
};
export const getSODetailsBySOIDTemp = async (soId) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSalesTemp/GetDistributorbySOfoeTemp?SOID=${soId}`;
  await axios.get(url).then(res => {

    responseList.data = res.data.data;
    responseList.status = false;
  })
    .catch(function (error) {

    });
  return responseList;
};
export const getSoDetailsByPointId = async (soPointId) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSalesTemp/GetSODetailsTempBySoPoint?SOPoint=${soPointId}`;
  await axios.get(url).then(res => {

    responseList.data = res.data.data;
    responseList.status = false;
  })
    .catch(function (error) {

    });
  return responseList;
};
export const salesSubmit = (submitData, productList, distributorInfo, soId, startDate) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;

  // const { intCustomer, monCustBalance, coaid, intsalesofficeid,delPointID,disPointID,distribCat } = submitData;
  let { areaID, disID, divisionID, teritoryID, zoneID, disPointID } = distributorInfo;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };

  
  



  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemID: 0,
      itemName: 0,
      intUomId: 0,
      itemUOMqty: 0,
      itemQuentityPic: 0,
    };


    // const quantity = productList[i].quantity;
    if (productList[i].itemQuentity> 0) {
      productData.itemID = productList[i].itemID;
      productData.itemName = productList[i].itemName;
      productData.intUomId = productList[i].intUomId;
      productData.prodTrdprice = productList[i].prodTrdprice;
      productData.prodTrdpricebypic = productList[i].prodTrdpricebypic;
      productData.itemUOMqty = parseInt(productList[i].itemUOMqty);
      productData.itemQuentityPic = isNaN(parseInt(productList[i].itemQuentityPic)) ? 0 : parseInt(productList[i].itemQuentityPic);
      // productData.itemQuentityPic = isNaN(parseInt(productList[i].itemQuentityPic)) ? 0 : parseInt(productList[i].itemQuentityPic);
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }
  //  return false;

  try {
    //api/RemoteOrder/InsertSalesOrder?ZoneID=1&DivisionID=1&AreaID=1&DistribPointID=1&DelPointID=1&CustomerID=1&MonCustBalance=1&Coaid=1&Intsalesofficeid=1
    //  let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrder?CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}`;
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/SecondarySalesEntrybyASM?ZoneID=${zoneID}&DivisionID=${divisionID}&AreaID=${areaID}&TerritoryID=${teritoryID}&DistPointID=${disPointID}&DistID=${disID}&EntryDate=${startDate}&SOEnroll=${soId}`;


    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {

        // return false;
        //   responseList.data = response.data;
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
};

export const getSoInformation = async (soPointId) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SecondarySales/GetSoInformation`;
  await axios.get(url).then(res => {
    responseList.data = res.data.data;
    responseList.status = false;
  })
    .catch(function (error) {

    });
  return responseList;
};
export const salesSubmitBySoPoint = (sOPointid,memoData, productList, startDate,selectStatus) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;



  // const { intCustomer, monCustBalance, coaid, intsalesofficeid,delPointID,disPointID,distribCat } = submitData;
  // let { areaID, disID, divisionID, teritoryID, zoneID, disPointID } = distributorInfo;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };

  let {orderValue,totalmemoQty,totalSkuQty,visitedOutlet}=memoData

  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemID: 0,
      itemName: 0,
      intUomId: 0,
      itemUOMqty: 0,
      itemQuentityPic: 0,
      salesStatusID:0

    };
    // const quantity = productList[i].quantity;
    if (productList[i].itemQuentityPic > 0 && productList[i].itemQuentityPic !==null) {
      productData.itemID = productList[i].itemID;
      productData.itemName = productList[i].itemName;
      productData.intUomId = productList[i].intUomId;
      productData.prodTrdprice = productList[i].prodTrdprice;
      productData.prodTrdpricebypic = productList[i].prodTrdpricebypic;
      productData.itemUOMqty = parseInt(productList[i].itemUOMqty);
      productData.salesStatusID=selectStatus
      productData.itemQuentityPic = isNaN(parseInt(productList[i].itemQuentityPic)) ? 0 : parseInt(productList[i].itemQuentityPic);
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }






  try {
    //api/RemoteOrder/InsertSalesOrder?ZoneID=1&DivisionID=1&AreaID=1&DistribPointID=1&DelPointID=1&CustomerID=1&MonCustBalance=1&Coaid=1&Intsalesofficeid=1
    //  let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/InsertSalesOrder?CustomerID=${intCustomer}&MonCustBalance=${monCustBalance}&Coaid=${coaid}&Intsalesofficeid=${intsalesofficeid}`;
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSApproval/SecondarySalesEntrySOPoint?SOPointid=${sOPointid}&orderValue=${orderValue}&totalmemoQty=${totalmemoQty}&totalSkuQty=${totalSkuQty}&visitedOutlet=${visitedOutlet}&EntryDate=${startDate}        `;


    // return false;
    axios
      .post(postUrl, newList)
      .then(function (response) {
         console.log('entryresponse :>> ', response);
        // return false;
        //   responseList.data = response.data;
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
        }
      })
      .catch(function (error) {

        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
        dispatch({ type: Types.SALES_PRODUCT_SUBMIT, payload: responseList });
      });
  } catch (error) {

  }

  
};
export const salesSubmitDemo = (submitData, productList, distributorInfo, soId, startDate,pointDetails) => async (dispatch) => {
 
  let newList = [];
  let quantityStatus = true;

  // const { intCustomer, monCustBalance, coaid, intsalesofficeid,delPointID,disPointID,distribCat } = submitData;
  // let { areaID, disID, divisionID, teritoryID, zoneID, disPointID } = distributorInfo;
  let { zoneID, divisionID, areaID, territoryID, disPointID, distribID,soPointID,soid } = pointDetails;
  let responseList = {
    submitLoading: true,
    data: {},
    status: false,
  };



 

  // dispatch({ type: Types.SALES_PRODUCT_SUBMIT_FEB, payload: responseList });
  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemID: 0,
      itemName: 0,
      intUomId: 0,
      itemUOMqty: 0,
      itemQuentityPic: 0,
    };
    // const quantity = productList[i].quantity;
    if (productList[i].itemQuentityPic > 0) {
      productData.itemID = productList[i].itemID;
      productData.itemName = productList[i].itemName;
      productData.intUomId = productList[i].intUomId;
      productData.prodTrdprice = productList[i].prodTrdprice;
      productData.prodTrdpricebypic = productList[i].prodTrdpricebypic;
      productData.itemUOMqty = parseInt(productList[i].itemUOMqty);
      productData.itemQuentityPic = isNaN(parseInt(productList[i].itemQuentityPic)) ? 0 : parseInt(productList[i].itemQuentityPic);
      newList.push(productData);
    }
  }
  if (newList.length == 0) {
    responseList.isLoading = false;
    showToast("error", "Please type at least One quantity");
    return false;
  }


  try {
 
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSalesTemp/SecondarySalesEntryTemp?ZoneID=${zoneID}&DivisionID=${divisionID}&AreaID=${areaID}&TerritoryID=${territoryID}&DistPointID=${disPointID}&DistID=${distribID}&EntryDate=${startDate}&SoPointId=${soPointID}&SOEnroll=${soid}`;


    axios
      .post(postUrl, newList)
      .then(function (response) {
       
        // return false;
        //   responseList.data = response.data;
        if (response.data.code === 200) {
          
          responseList.submitLoading = false;
          responseList.status = response.data.status;
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT_FEB, payload: responseList });
        } else {
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.SALES_PRODUCT_SUBMIT_FEB, payload: responseList });
        }
      })
      .catch(function (error) {
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {
    
  }
  
};



export const salesUpdate = (invoiceId, productList) => async (dispatch) => {
  let newList = [];
  let quantityStatus = true;


  for (let i = 0; i < productList.length; i++) {
    let productData = {
      itemId: 0,
      itemUOM: 0,
      itemCOAID: 0,
      itemQuentity: 0,
      itemPrice: 0,
      itemVatPrice: 0,
    };
    const quantity = productList[i].quantity;
    if (productList[i].itemQuentity > 0) {
      productData.itemId = productList[i].itemID;
      productData.itemUOM = productList[i].intUomId;
      productData.itemCOAID = productList[i].itemCoaid;
      productData.itemQuentity = productList[i].itemQuentity;
      productData.itemPrice = productList[i].itemPrice;
      productData.itemVatPrice = productList[i].vatPrice;
      newList.push(productData);
    }

  }
  if (newList.length == 0) {
    showToast("error", "Please type at least One quantity");
    return false;
  }


  // const { intCustomer, monCustBalance, coaid, intsalesofficeid } = submitData;
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderUpdate?SalesOrderInvID=${invoiceId}`;


    axios
      .put(postUrl, newList)
      .then(function (response) {

        //   responseList.data = response.data;

        if (response.data.code === 200) {
          responseList.isLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
        } else {
          showToast("error", response.data.messege);
        }
      })
      .catch(function (error) {

        responseList.isLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", message);
      });
  } catch (error) {

  }

  dispatch({ type: Types.SALES_PRODUCT_UPDATE, payload: responseList });
};

export const salesDelete = (id, areaId) => (dispatch) => {

  let isLoading = true;
  dispatch({ type: Types.DELETE_SALES_TITEM, payload: isLoading })
  axios.delete(`${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/SalesOrderDelete?SalesOrderInvID=${id}`)
    .then((res) => {

      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch({ type: Types.DELETE_SALES_TITEM, payload: false });
        dispatch(getSalesList(areaId))
      }
    })
}
export const cleanRemoteSalesData = (id) => (dispatch) => {
  dispatch({ type: Types.CLEAR_REMOTE_SALES_DATA, payload: null });
}
export const cleanRemoteSalesDatafEB = (id) => (dispatch) => {

  dispatch({ type: Types.CLEAR_REMOTE_SALES_DATA_FEB, payload: null });
}
export const getLockSystemData = async () => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SSalesTemp/ApplicationLockSystem`;
  await axios.get(url).then(res => {
    responseList.data = res.data.data;
    responseList.status = false;
  })
    .catch(function (error) {

    });
  return responseList;
}

