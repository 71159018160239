import React, { useState } from "react";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import FullScreenButton from "../../../../_partials/dashboards/FullScreenButton";
import Clock from "../../../../../modules/Sales/information/components/Dashboard/Clock";
import Calendar from "../../../../../modules/Sales/information/components/Dashboard/Calender";
import { GetWorkingDayList } from "../../../../../modules/Sales/information/components/Dashboard/DashboardCartAction";
import { useDispatch } from "react-redux";

export function UserProfileDropdown(props) {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false); // New state for calendar visibility

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const closeProfileDropdown = () => {
    setIsProfileOpen(false);
  };

  const toggleNotificationsDrawer = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen); // Toggle calendar visibility
  };

  const logoutButton = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("access_token");
    localStorage.removeItem("showCard");
    sessionStorage.removeItem('lastTimeStamp');
    window.location.href = "/auth/login";
  };

  const getUserInitials = (name) => {
    if (!name) return "U";
    const names = name.split(" ");
    return names.length > 1
      ? names[0][0] + names[1][0]
      : names[0][0];
  };

  const slidingSectionStyle = {
    position: "fixed",
    top: "50px",
    right: 0,
    width: "350px",
    height: "calc(100vh - 50px)",
    backgroundColor: "white",
    boxShadow: "-4px 0 8px rgba(0, 0, 0, 0.2)",
    transform: isProfileOpen ? "translateX(0)" : "translateX(100%)",
    transition: "transform 0.3s ease",
    zIndex: 1000,
    overflowY: "auto",
  };

  const notificationsStyle = {
    position: "fixed",
    top: "50px",
    right: 0,
    width: "350px",
    height: "calc(100vh - 50px)",
    backgroundColor: "white",
    boxShadow: "-4px 0 8px rgba(0, 0, 0, 0.2)",
    transform: isNotificationsOpen ? "translateX(0)" : "translateX(100%)",
    transition: "transform 0.3s ease",
    zIndex: 1000,
    padding: "5px",
    overflowY: "auto",
  };

  const calendarStyle = {
    position: "fixed",
    top: "50px",
    right: 0,
    width: "350px",
    height: "calc(100vh - 50px)",
    backgroundColor: "white",
    boxShadow: "-4px 0 8px rgba(0, 0, 0, 0.2)",
    transform: isCalendarOpen ? "translateX(0)" : "translateX(100%)",
    transition: "transform 0.3s ease",
    zIndex: 1000,
    padding: "15px",
    overflowY: "auto",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "#FA8072",
    color: "#fff",
    border: "none",
    borderRadius: "10%",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    cursor: "pointer",
    fontWeight: "bold"
  };

  
  return (
    <>
      <div className="d-flex align-items-center" style={{ position: "relative" }}>

        <div className="text-center mr-5"><Clock /></div>
        <div
          className="btn btn-icon btn-clean d-flex align-items-center btn-lg text-light mr-4"
          onClick={toggleCalendar}
          style={{ cursor: "pointer" }}
        >

          <SVG
            src={toAbsoluteUrl("/media/svg/logos/event-calender.svg")}
            style={{ width: '27px', height: '27px' }}
          />
        </div>

        <FullScreenButton />


        {/* Calendar Drawer */}
        <div style={calendarStyle}>
          
          {isCalendarOpen && (
            <>
              <button style={closeButtonStyle} onClick={toggleCalendar}>
                X
              </button>
              <Calendar />
            </>
          )}
        </div>

        {/* Profile Sliding Section */}
        <div style={slidingSectionStyle}>
          {isProfileOpen && (
            <>
              <div>
                <button style={closeButtonStyle} onClick={closeProfileDropdown}>
                  X
                </button>
                <div className="d-flex align-items-center p-4 mt-3">
                  <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                    <span className="symbol-label text-success font-weight-bold font-size-h4">
                      {getUserInitials(props.user.employee_Name)}
                    </span>
                  </div>
                  <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                    {props.user.employee_Name}
                  </div>
                </div>
                <div className="navi navi-spacer-x-0 pt-5">
                  <Link className="navi-item" to={`/remoteSales/profile/update`}>
                    <div className="navi-link p-6" style={{ backgroundColor: '#e6ffe6' }}>
                      <div className="navi-icon mr-2">
                        <i className="flaticon2-rocket-1 text-success" />
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold">Password Update</div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Notifications Drawer */}
        <div style={notificationsStyle}>
          {isNotificationsOpen && (
            <>
              <button style={closeButtonStyle} onClick={toggleNotificationsDrawer}>
                X
              </button>
              <div>
                <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #eee", padding: "15px 10px" }}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/logos/chat-sms-svgrepo-com.svg")}
                    style={{ width: '30px', height: '30px', marginRight: '10px' }}
                  />
                  <div>
                    <div className="font-weight-bold">Demo Notification 1</div>
                    <div className="text-muted">This is a demo notification message.</div>
                    <div style={{ color: "#6c757d", fontSize: "0.875rem" }}>2 days ago</div>
                    <div style={{ color: "#6c757d", fontSize: "0.875rem", marginTop: '5px' }}>Sent by Akij Insaf IT</div> {/* Added sender information */}
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #eee", padding: "15px 10px" }}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/logos/chat-sms-svgrepo-com.svg")}
                    style={{ width: '30px', height: '30px', marginRight: '10px' }}
                  />
                  <div>
                    <div className="font-weight-bold">Demo Notification 2</div>
                    <div className="text-muted">This is another demo notification message.</div>
                    <div style={{ color: "#6c757d", fontSize: "0.875rem" }}>4 days ago</div>
                    <div style={{ color: "#6c757d", fontSize: "0.875rem", marginTop: '5px' }}>Sent by Akij Insaf IT</div> {/* Added sender information */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>



        {/* Notifications Icon */}
        <div
          className="btn btn-icon btn-clean btn-lg mr-0 pulse pulse-primary"
          id="kt_quick_notifications_toggle"
          onClick={toggleNotificationsDrawer}
        >
          <span className="svg-icon svg-icon-xl svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/logos/notification.svg")}
              style={{ width: '25px', height: '25px' }}
            />
          </span>
          <>
            <span className="pulse-ring"></span>
            <span className="pulse-ring" />
          </>
        </div>
        <div
          className="btn btn-icon btn-clean d-flex align-items-center btn-lg text-light"
          onClick={toggleProfileDropdown}
          style={{ cursor: "pointer" }}
        >
          <SVG
            src={toAbsoluteUrl("/media/svg/logos/user-account.svg")}
            style={{ width: '25px', height: '25px' }}
          />
        </div>
        <span style={{ textTransform: 'uppercase' }} className="font-weight-bold mr-0 text-white smallscreendisplaynone">
          {props.user?.employee_Name}
        </span>

        {/* Logout Button */}
        <button
          className="btn btn-light-danger font-weight-bold mr-3"
          onClick={logoutButton}
          style={{ marginLeft: "10px", height: "38px" }}
        >
          Logout
        </button>
      </div>
    </>
  );
}
