export const GET_SECONDARY_SALES_APPROVED_REPORT = "GET_SECONDARY_SALES_APPROVED_REPORT";
export const GET_SECONDARY_SALES_APPROVED_DETAILS = "GET_SECONDARY_SALES_APPROVED_DETAILS";
export const GET_SECONDARY_SALES_HANDLE_INPUT = "GET_SECONDARY_SALES_HANDLE_INPUT";
export const GET_ASM_APPROVED_LIST = "GET_ASM_APPROVED_LIST";
export const GET_ASM_APPROVED_LIST_DETAILS = "GET_ASM_APPROVED_LIST_DETAILS";
export const GET_SALES_PRODUCT_CUMALATIVE_LIST = "GET_SALES_PRODUCT_CUMALATIVE_LIST";
export const GET_SECONDARY_SALES_DATE_WISE = "GET_SECONDARY_SALES_DATE_WISE";
export const GET_SALES_PRODUCT_CUMALATIVE_LIST_BY_SO = "GET_SALES_PRODUCT_CUMALATIVE_LIST_BY_SO";
export const GET_SALES_CLEAN = "GET_SALES_CLEAN";
export const GET_BRAED_BURN_CUMALATIVE = "GET_BRAED_BURN_CUMALATIVE";
export const GET_SKUWISE_REPORT = "GET_SKUWISE_REPORT";
export const GET_PRIMARY_SKUWISE_REPORT = "GET_PRIMARY_SKUWISE_REPORT";
export const GET_SO_SKU_REPORT = "GET_SO_SKU_REPORT";
export const GET_SO_SKU_REPORT_FLAT = "GET_SO_SKU_REPORT_FLAT";
export const GET_DISTRIBUTOR_SKU_REPORT = "GET_DISTRIBUTOR_SKU_REPORT";
export const GET_DELIVERY_REPORT_BY_DISTRIBUTOR = "GET_DELIVERY_REPORT_BY_DISTRIBUTOR";
export const GET_DELIVERY_SKU_REPORT = "GET_DELIVERY_SKU_REPORT";
export const GET_SALES_SKU_REPORT_ALL_ZONE = "GET_SALES_SKU_REPORT_ALL_ZONE";
export const GET_SKU_WISE_TARGET = "GET_SKU_WISE_TARGET";
export const GET_TARTGET_SALES_CLEAN = "GET_TARTGET_SALES_CLEAN";
export const GET_BREDBURN_PIVOT_REPORT = "GET_BREDBURN_PIVOT_REPORT";
export const GET_CUSTOEMR_LEDGER = "GET_CUSTOEMR_LEDGER";
export const GET_DISTRIBUTOR_STOCK = "GET_DISTRIBUTOR_STOCK";
export const GET_SO_ACHIVEMENT_REPORT = "GET_SO_ACHIVEMENT_REPORT";
export const GET_SECONDARY_STATUS = "GET_SECONDARY_STATUS";

export const REMOTE_CHECKBOX_STATUS = "REMOTE_CHECKBOX_STATUS";
export const REMOTE_CHECKBOX_ALL = "REMOTE_CHECKBOX_ALL";
export const GET_ROLL_BACK_LIST = "GET_ROLL_BACK_LIST";
export const GET_DISTRIBUTOR_STOCK_PIVOT = "GET_DISTRIBUTOR_STOCK_PIVOT";
export const GET_PRIMARY_DEMAND_SKU_WISE = "GET_PRIMARY_DEMAND_SKU_WISE";
export const GET_PRIMARY_DEMAND_CUM = "GET_PRIMARY_DEMAND_CUM";
export const GET_SECONDARY_UNAPPROVED_LIST = "GET_SECONDARY_UNAPPROVED_LIST";
export const GET_AFML_PRODUCT_LIFTING_BY_DISTRIBUTOR = "GET_AFML_PRODUCT_LIFTING_BY_DISTRIBUTOR";
export const GET_DISTRIBUTOR_WISE_PRODUCT_SKU_DETAILS = "GET_DISTRIBUTOR_WISE_PRODUCT_SKU_DETAILS";
export const GET_SO_REPORT = "GET_SO_REPORT";
export const GET_PRIMARY_DEMAND_STATUS = "GET_PRIMARY_DEMAND_STATUS";
export const GET_STRIKE_RATE_OF_SECONDARY = "GET_STRIKE_RATE_OF_SECONDARY";
export const GET_DAMAGE_SKU_WISE_LIST_FOR_ACCOUNTS = "GET_DAMAGE_SKU_WISE_LIST_FOR_ACCOUNTS";
export const GET_STRIKE_RATE_OF_SECONDARY_FOR_SO = "GET_STRIKE_RATE_OF_SECONDARY_FOR_SO";
export const CHANGE_DELIVERY_INPUT = "CHANGE_DELIVERY_INPUT";

export const GET_TARGET_ACV_DISPOINT = "GET_TARGET_ACV_DISPOINT";
export const GET_STOCK_REPORT_NEW = "GET_STOCK_REPORT_NEW";
export const GET_STOCK_REPORT_MODIFIED = "GET_STOCK_REPORT_MODIFIED";
export const GET_TARGET_ACV_PRODUCT_WISE = "GET_TARGET_ACV_PRODUCT_WISE";
export const GET_DELIVERY_SKU_REPORT_PRODUCT_WISE = "GET_DELIVERY_SKU_REPORT_PRODUCT_WISE";
export const GET_TARGET_ACV_DISPOINT_SALES_WISE = "GET_TARGET_ACV_DISPOINT_SALES_WISE";
export const GET_AFML_PRIMARY_SALES_BY_DISTRIBUTOR = "GET_AFML_PRIMARY_SALES_BY_DISTRIBUTOR";
export const GET_ZONE_DATA_TARVSACHV = "GET_ZONE_DATA_TARVSACHV";
export const GET_DIVISION_DATA_TARVSACHV = "GET_DIVISION_DATA_TARVSACHV";
export const GET_AREA_DATA_TARVSACHV = "GET_AREA_DATA_TARVSACHV";
export const GET_TERRITORY_DATA_TARVSACHV = "GET_TERRITORY_DATA_TARVSACHV";
export const GET_SO_SKU_REPORT_V2 = "GET_SO_SKU_REPORT_V2";
export const SET_LIMITED_DIVISION_REPORTS = "SET_LIMITED_DIVISION_REPORTS";
export const SET_LIMITED_REGION_REPORTS = "SET_LIMITED_REGION_REPORTS";
export const SET_LIMITED_TERRITORY_REPORTS = "SET_LIMITED_TERRITORY_REPORTS";
export const SET_BOTTOM_TERRITORY_REPORTS = "SET_BOTTOM_TERRITORY_REPORTS";
export const SET_BOTTOM_RM_REPORTS = "SET_BOTTOM_RM_REPORTS";
export const SET_BOTTOM_3_DSM_REPORTS = "SET_BOTTOM_3_DSM_REPORTS";
export const GET_TERRITORY_DATA_ACTIVE_INACTIVE = "GET_TERRITORY_DATA_ACTIVE_INACTIVE";
export const GET_SO_WISE_IMS_REPORT_VALUE = "GET_SO_WISE_IMS_REPORT_VALUE";
export const GET_TOP_SELLING_SKU = "GET_TOP_SELLING_SKU";
export const GET_IMS_FOCUS_SKU = "GET_IMS_FOCUS_SKU";




