import Axios from "axios";
import * as Types from "./DashboardType";

export const InputHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_PRODUCT_INPUT, payload: formData });
};
export const InputReportHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_PRODUCT_INPUT_REPORT, payload: formData });
};


export const handleDateManageForDashboardCart = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_HANDLE_DATE_MANAGE_FOR_DASHBOARD, payload: formData });
};


export const GetDashboardTargetDate = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine,selectSo) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_DASHBOARD_DATA, payload: responseList });
  let url = "";
  //url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDashboardSecondaryChart?ZoneID=${ZoneID}&DivisionID=${DivisionID}&AreaID=${AreaID}&AreaID=${AreaID}&Territory=${Territory}&Fdate=2022-12-01&Tdate=2022-12-27`;
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDashboardSecondaryChart?`;
  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  if (selectSo !== null) {
    url += `&soPoint=${selectSo}`
  }
  console.log("GetDashboardTargetDate",url);
  await Axios.get(url).then((res) => {
  
    responseList.data = res.data.data;
    responseList.isLoading = false;
  });
  
  dispatch({ type: Types.GET_DASHBOARD_DATA, payload: responseList });
};
export const GetABLMOnthWiseData = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine,selectSo ) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.ABL_SALES_SINCE_INCEPTION_FOR_DASHBOARD, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetMonthToMonthSecondarySalesTrend?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  if (selectSo !== null) {
    url += `&soPoint=${selectSo}`
  }
  await Axios.get(url).then((res) => {
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
    
  });
  dispatch({ type: Types.ABL_SALES_SINCE_INCEPTION_FOR_DASHBOARD, payload: responseList });
};
export const GetDamageData = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine,selectSo) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_DAMAGE_DATA, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDamageProductLine?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  if (selectSo !== null) {
    url += `&soPoint=${selectSo}`
  }
  await Axios.get(url).then((res) => {
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
    
  });
  dispatch({ type: Types.GET_DAMAGE_DATA, payload: responseList });
};
export const GetCategoryType = () => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductCategoryList`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    
  });
  dispatch({ type: Types.GET_CATEGORY_TYPE, payload: responseList });
};
export const GetProductType = (CategoryId,startDate,toDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductListByCategory?CategoryId=${CategoryId}&Fdate=${startDate}&Tdate=${toDate}`;

  await Axios.get(url).then((res) => {
    console.log("SKU",res.data.data)
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PRODUCTION_LINE_BY_PRODUCTION, payload: responseList });
};
export const GetNSMType = (zoneId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetNsMListByZoneId?zoneId=${zoneId}`;
  await Axios.get(url).then((res) => {
    console.log('division',res)
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_NSM_TYPE, payload: responseList });
};
export const GetDSMType = (divisionId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDsmListByDivisionId?divisionId=${divisionId}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DSM_TYPE, payload: responseList });
};

export const GetRMType = (areaId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetRmListByAreaId?areaId=${areaId}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_RM_TYPE, payload: responseList });
};
export const GetTSMType = (territoryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetTsmListByTerritoryId?territoryId=${territoryId}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TSM_TYPE, payload: responseList });
};
export const GetLineType = (territoryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductLine?`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_LINE_TYPE, payload: responseList });
};
export const GetProductGroup = (territoryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductGroup?`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PRODUCT_GROUP_TYPE, payload: responseList });
};

export const GetLocationWiseSales = (ZoneID=0, DivisionID=0, AreaID=0, Territory=0,Fdate,Tdate,category=0,ProductId=0,ProductGroup=0,productionLine=0) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  // dispatch({ type: Types.GET_LOCATION_WISE_DATA, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetLocationWiseSales?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  await Axios.get(url).then((res) => {
   
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
    
  });
  dispatch({ type: Types.GET_LOCATION_WISE_DATA, payload: responseList });
};
export const GetLocationWiseSalesForDonut = (ZoneID=0, DivisionID=0, AreaID=0, Territory=0,Fdate,Tdate,category=0,ProductId=0,ProductGroup=0,productionLine=0) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  dispatch({ type: Types.GET_LOCATION_WISE_DATA_FOR_DONUT, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetLocationWiseSales?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  await Axios.get(url).then((res) => {
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    
  });
  dispatch({ type: Types.GET_LOCATION_WISE_DATA_FOR_DONUT, payload: responseList });
};
export const clearList = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_LIST, payload: data });
};
export const GetProductionLine = (territoryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductionLine?`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PRODUCTION_LINE, payload: responseList });
};
export const GetProductListByCategory = (CId,Fdate,Tdate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductListByCategory?CategoryId=${CId}&Fdate=${Fdate}&Tdate=${Tdate}`;
  await Axios.get(url).then((res) => {
    console.log('sku url', url)
    console.log('sku res', res)
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PRODUCTION_LINE_BY_PRODUCTION, payload: responseList });
};

export const GetPermissionDropDown = async (name, value) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/GetPermissionDropDown`;
    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data == null ? [] : response.data.data;
      })
      .catch(function (error) {
      });
  } catch (error) {

  }
  return responseList;
};
export const GetDisZDAreaTerriDistbpoints = async (name, value) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/GetDisPermissionDropDown`;
    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data == null ? [] : response.data.data;
      })
      .catch(function (error) {
      });
  } catch (error) {

  }
  return responseList;
};
export const getSoList = async (terrioryId) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}GetSOPointListbyAreaForDashboard?TerritoryId=${terrioryId}`;
    console.log('geturl', geturl);
    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;

      })

      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};
export const GetProductListByGroup = (Gid) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductListBySalesGroup?groupId=${Gid}`;
  await Axios.get(url).then((res) => {
    console.log('sku url', url)
    console.log('sku res', res)
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PRODUCTION_LINE_BY_PRODUCTION, payload: responseList });
};

export const GetWorkingDayList = (fDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetWorkingDayList?Fdate=${fDate}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_WORKING_DAY_LIST, payload: responseList });
};
export const GetPreviousMonthWorkingDayList = (fDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetPreviousMonthWorkingDayList?Fdate=${fDate}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PREVIOUS_WORKING_DAY_LIST, payload: responseList });
};